import {
  Button,
  HStack,
  Stack,
  VStack,
  Image,
  Text,
  Input,
  Box,
  IconButton,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Center,
} from "@chakra-ui/react";
import { FaArrowDown } from "react-icons/fa";
import logo from "./assets/logo.png";
import eth from "./assets/eth.png";
import cpt from "./assets/cpt.png";
import wc from "./assets/wc.png";
import mm from "./assets/mm.png";
import abi from "./abi.json";
import Web3 from "web3";
import { useEffect, useState } from "react";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import { erc20Abi } from "./erc20";
import { useDisclosure } from "@chakra-ui/react";
import { ethers } from "ethers";
import "./App.css";
function App() {
  const toast = useToast();
  const router = "0x4752ba5DBc23f44D87826276BF6Fd6b1C372aD24";
  const web3 = new Web3("https://arbitrum-one-rpc.publicnode.com");
  const [amountIn, setAmountIn] = useState(0);
  const [amountOut, setAmountOut] = useState(0);
  const [isTyping, setIsTyping] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(null);
  const [etherBalance, setEtherBalance] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [approved, setApproved] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isMC, setIsMC] = useState(false);
  const [isWC, setIsWC] = useState(false);
  const [providerInstance, setProviderInstance] = useState(null); // State for providerInstance

  const tokens = [
    {
      name: "ETH",
      address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      image: eth,
      balance: etherBalance,
    },
    {
      name: "CPT",
      address: "0xEd7d16E3aD3D98E623C1dF356A810867f44F4D8d",
      image: cpt,
      balance: tokenBalance,
    },
  ];
  const [tokenA, setTokenA] = useState(tokens[0]);
  const [tokenB, setTokenB] = useState(tokens[1]);

  const switchToken = () => {
    setTokenA(tokenB);
    setTokenB(tokenA);
    setAmountIn("0");
    setAmountOut("0");
  };

  const fetchAmounts = async () => {
    try {
      if (amountIn && parseFloat(amountIn) > 0) {
        const routerContract = new web3.eth.Contract(abi, router);
        const amountsOut = await routerContract.methods
          .getAmountsOut(web3.utils.toWei(amountIn.toString(), "ether"), [
            tokenA.address,
            tokenB.address,
          ])
          .call();

        if (amountsOut.length > 1) {
          const output = web3.utils.fromWei(amountsOut[1], "ether");
          setAmountOut(parseFloat(output).toFixed(9));
        } else {
          setAmountOut(0);
        }
      } else {
        setAmountOut(0);
      }
    } catch (error) {
      console.error("Error fetching amounts:", error);
      setAmountOut("0");
    }
  };

  const handleAmountInChange = (e) => {
    const inputValue = e.target.value;
    console.log("INPUT VALUE", inputValue);
    if (
      inputValue.trim() === "" ||
      isNaN(inputValue) ||
      inputValue === null ||
      !inputValue
    ) {
      setAmountIn(0);
      setAmountOut(0);
      setTimeout(() => {
        setAmountIn("");
      }, 500);
      setIsTyping(!isTyping);
    } else {
      setAmountIn(inputValue);
      setIsTyping(!isTyping);
    }
  };

  useEffect(() => {
    fetchAmounts();
  }, [isTyping, amountIn]);

  const WalletConnect = async () => {
    setIsWC(true);
    setIsMC(false);
    try {
      const provider = await EthereumProvider.init({
        projectId: "5dbf5fe39b0f5e601ccb82ac04a2fb48",
        chains: [42161],
        showQrModal: true,
      });

      await provider.connect();

      if (provider) {
        await provider.enable();
        const accounts = await provider.request({
          method: "eth_requestAccounts",
        });

        if (accounts.length > 0) {
          const account = accounts[0]?.toString();
          setWalletAddress(account);
          const balance = await web3.eth.getBalance(account);
          const balanceInEther = parseFloat(
            web3.utils.fromWei(balance, "ether")
          ).toFixed(6);
          setEtherBalance(balanceInEther);
          const tokenContract = new web3.eth.Contract(
            erc20Abi,
            "0xEd7d16E3aD3D98E623C1dF356A810867f44F4D8d"
          );
          const tokenBalance = await tokenContract.methods
            .balanceOf(account)
            .call();
          const tokenBalanceInEther = parseFloat(
            web3.utils.fromWei(tokenBalance, "ether")
          ).toFixed(6);
          setTokenBalance(tokenBalanceInEther);
          setProviderInstance(provider);
        } else {
          console.error("No Ethereum accounts available.");
        }
      } else {
        console.error("Failed to initialize WalletConnect provider.");
      }
    } catch (err) {
      console.error("Error in WalletConnect:", err);
    }
  };

  const disconnectWallet = async () => {
    if (isMC) {
      setWalletAddress(null);
    } else {
      try {
        if (!providerInstance) {
          console.error("Provider is not available.");
          return;
        }
        await providerInstance.disconnect();
        setProviderInstance(null);

        toast({
          title: "Disconnected from WalletConnect provider.",
          status: "success",
          colorScheme: "red",
          position: "top-right",
          isClosable: true,
        });
        console.log("Disconnected from WalletConnect provider.");
        setWalletAddress(null);
      } catch (err) {
        console.error("Error disconnecting from WalletConnect provider:", err);
      }
    }
  };

  const connectWallet = async () => {
    setIsWC(false);
    setIsMC(true);

    try {
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const address = await provider.send("eth_accounts");
        setWalletAddress(address[0]);

        const currentChainId = await window.ethereum.request({
          method: "eth_chainId",
        });
        if (currentChainId !== "0xa4b1") {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0xa4b1" }],
          });
        }
        const balance = await provider.getBalance(address[0]);
        const etherBalance = ethers.utils.formatEther(balance);
        setEtherBalance(parseFloat(etherBalance).toFixed(6));

        const tokenContract = new ethers.Contract(
          "0xEd7d16E3aD3D98E623C1dF356A810867f44F4D8d",
          erc20Abi,
          provider
        );
        const tokenBalance = await tokenContract.balanceOf(address[0]);
        const formattedTokenBalance = ethers.utils.formatUnits(
          tokenBalance,
          18
        );
        setTokenBalance(parseFloat(formattedTokenBalance).toFixed(6));
      }
    } catch (error) {
      console.error("Error connecting wallet:", error);
      // Handle error, maybe show a message to the user
    }
  };

  const approveTokens = async () => {
    try {
      if (providerInstance) {
        const accounts = await providerInstance.request({
          method: "eth_requestAccounts",
        });
        if (accounts.length > 0) {
          const account = accounts[0];
          const web3 = new Web3(providerInstance);

          if (tokenA.name === "CPT") {
            const tokenContract = new web3.eth.Contract(
              erc20Abi,
              tokenA.address
            );

            const gasLimit = await tokenContract.methods
              .approve(router, web3.utils.toWei(amountIn.toString(), "ether"))
              .estimateGas({ from: account });

            const approve = await tokenContract.methods
              .approve(router, web3.utils.toWei(amountIn.toString(), "ether"))
              .send({
                from: account,
                gas: gasLimit,
              });
          } else {
            const tokenContract = new web3.eth.Contract(
              erc20Abi,
              tokenA.address
            );
            const gasLimit = await tokenContract.methods
              .approve(router, web3.utils.toWei(amountIn.toString(), "ether"))
              .estimateGas({ from: account });
            const approve = await tokenContract.methods
              .approve(router, web3.utils.toWei(amountIn.toString(), "ether"))
              .send({
                from: account,
                gas: gasLimit,
              });
          }
        }
      }
    } catch (err) {
      console.error("Error interacting with contract:", err);
      toast({
        //err.message
        status: "error",
        title: "Error",
        description: "Insufficient funds to swap.",
        isClosable: true,
        colorScheme: "red",
        position: "top-right",
        duration: 3000,
      });
    }
  };

  const swapTokens = async () => {
    try {
      if (providerInstance) {
        const accounts = await providerInstance.request({
          method: "eth_requestAccounts",
        });
        if (accounts.length > 0) {
          const account = accounts[0];
          const web3 = new Web3(providerInstance);

          const routerContract = new web3.eth.Contract(abi, router);

          if (tokenA.name === "CPT") {
            const amountInWei = web3.utils.toWei(amountIn.toString(), "ether");
            const amountOutWei = web3.utils.toWei(
              amountOut.toString(),
              "ether"
            );

            console.log(`Amount In (Wei): ${amountInWei}`);
            console.log(`Amount Out (Wei): ${amountOutWei}`);

            const gasLimitSwap = await routerContract.methods
              .swapTokensForExactETH(
                amountOutWei,
                amountInWei,
                [tokenA.address, tokenB.address],
                account,
                Math.floor(Date.now() / 1000) + 3600
              )
              .estimateGas({ from: account });

            const tx = await routerContract.methods
              .swapTokensForExactETH(
                amountOutWei,
                amountInWei,
                [tokenA.address, tokenB.address],
                account,
                Math.floor(Date.now() / 1000) + 3600
              )
              .send({
                from: account,
                gas: gasLimitSwap,
              });

            toast({
              status: "success",
              title: "Congratulations! Cripbot tokens swapped successfully",
              isClosable: true,
              colorScheme: "green",
              position: "top-right",
              duration: 3000,
            });
          } else {
            console.log("Swapping ETH for tokens...");

            const amountInWei = web3.utils.toWei(amountIn.toString(), "ether");
            const minAmountOutWei = web3.utils.toWei(
              amountOut.toString(),
              "ether"
            );

            console.log(`Amount In (ETH in Wei): ${amountInWei}`);
            console.log(
              `Minimum Amount Out (Tokens in Wei): ${minAmountOutWei}`
            );

            const gasLimitSwap = await routerContract.methods
              .swapExactETHForTokens(
                minAmountOutWei, // Minimum amount of tokens to receive
                [tokenA.address, tokenB.address],
                account,
                Math.floor(Date.now() / 1000) + 3600
              )
              .estimateGas({ from: account, value: amountInWei });

            const tx = await routerContract.methods
              .swapExactETHForTokens(
                minAmountOutWei, // Minimum amount of tokens to receive
                [tokenA.address, tokenB.address],
                account,
                Math.floor(Date.now() / 1000) + 3600
              )
              .send({
                from: account,
                value: amountInWei,
                gas: gasLimitSwap,
              });

            await tx.wait();

            toast({
              status: "success",
              title: "Tokens swapped successfully",
              isClosable: true,
              colorScheme: "green",
              position: "top-right",
              duration: 3000,
            });
          }
        }
      }
    } catch (err) {
      console.error("Error interacting with contract:", err);
      toast({
        status: "error",
        title: "Error",
        description: err.message || "Insufficient funds to swap.",
        isClosable: true,
        colorScheme: "red",
        position: "top-right",
        duration: 3000,
      });
    }
  };

  const handleTransaction = async () => {
    await approveTokens();
    setTimeout(async () => {
      await swapTokens();
    }, 2000);
  };

  const swapTokensMM = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();

        const gasPriceGwei = 1;
        const gasPriceWei = ethers.utils.parseUnits(
          gasPriceGwei.toString(),
          "gwei"
        );

        if (tokenA.name === "CPT") {
          const tokenContract = new ethers.Contract(
            tokenA.address,
            erc20Abi,
            signer
          );
          const routerContract = new ethers.Contract(router, abi, signer);

          const approveTx = await tokenContract.approve(
            router,
            ethers.utils.parseUnits(amountIn.toString(), "ether")
          );
          await approveTx.wait(); // Wait for approval transaction to be mined

          toast({
            status: "success",
            title: "Tokens approved for transaction!",
            isClosable: true,
            colorScheme: "green",
            position: "top-right",
            duration: 5000,
          });

          const gasEstimate =
            await routerContract.estimateGas.swapTokensForExactETH(
              ethers.utils.parseEther(amountOut),
              ethers.utils.parseEther(amountIn),
              [tokenA.address, tokenB.address],
              walletAddress,
              Math.floor(Date.now() / 1000) + 3600
            );

          const tx = await routerContract.swapTokensForExactETH(
            ethers.utils.parseEther(amountOut),
            ethers.utils.parseEther(amountIn),
            [tokenA.address, tokenB.address],
            walletAddress,
            Math.floor(Date.now() / 1000) + 3600,
            { gasPrice: gasPriceWei, gasLimit: gasEstimate }
          );
          await tx.wait();

          toast({
            status: "success",
            title: "Congratulations! Cripbot tokens swapped successfully",
            isClosable: true,
            colorScheme: "green",
            position: "top-right",
            duration: 3000,
          });
          setAmountIn(0);
          setAmountOut(0);
          const balance = await provider.getBalance(walletAddress);
          const etherBalance = ethers.utils.formatEther(balance);
          setEtherBalance(parseFloat(etherBalance).toFixed(6));

          const tokenContractF = new ethers.Contract(
            "0xEd7d16E3aD3D98E623C1dF356A810867f44F4D8d",
            erc20Abi,
            provider
          );
          const tokenBalance = await tokenContractF.balanceOf(walletAddress);
          const formattedTokenBalance = ethers.utils.formatUnits(
            tokenBalance,
            18
          );
          setTokenBalance(parseFloat(formattedTokenBalance).toFixed(6));
        } else {
          const routerContract = new ethers.Contract(router, abi, signer);
          const tokenContract = new ethers.Contract(
            tokenA.address,
            erc20Abi,
            signer
          );

          const approveTx = await tokenContract.approve(
            router,
            ethers.utils.parseUnits(amountIn.toString(), "ether")
          );
          await approveTx.wait(); // Wait for approval transaction to be mined

          const gasEstimate =
            await routerContract.estimateGas.swapExactETHForTokens(
              ethers.utils.parseEther("0.1"),
              [tokenA.address, tokenB.address],
              walletAddress,
              Math.floor(Date.now() / 1000) + 3600,
              {
                value: ethers.utils.parseEther(amountIn),
              }
            );

          const tx = await routerContract.swapExactETHForTokens(
            ethers.utils.parseEther("0.1"),
            [tokenA.address, tokenB.address],
            walletAddress,
            Math.floor(Date.now() / 1000) + 3600,
            {
              value: ethers.utils.parseEther(amountIn),
              gasPrice: gasPriceWei,
              gasLimit: gasEstimate,
            }
          );
          await tx.wait();

          toast({
            status: "success",
            title: "Congratulations! Tokens swapped successfully",
            isClosable: true,
            colorScheme: "green",
            position: "top-right",
            duration: 3000,
          });
          setAmountIn(0);
          setAmountOut(0);
          const balance = await provider.getBalance(walletAddress);
          const etherBalance = ethers.utils.formatEther(balance);
          setEtherBalance(parseFloat(etherBalance).toFixed(6));

          const tokenContractF = new ethers.Contract(
            "0xEd7d16E3aD3D98E623C1dF356A810867f44F4D8d",
            erc20Abi,
            provider
          );
          const tokenBalance = await tokenContractF.balanceOf(walletAddress);
          const formattedTokenBalance = ethers.utils.formatUnits(
            tokenBalance,
            18
          );
          setTokenBalance(parseFloat(formattedTokenBalance).toFixed(6));
        }
      }
    } catch (err) {
      console.error("Error interacting with contract:", err);
      toast({
        status: "error",
        title: "Error",
        description: "Insufficient funds to swap.",
        isClosable: true,
        colorScheme: "red",
        position: "top-right",
        duration: 3000,
      });
    }
  };

  // HANDLE MAX
  const handleMax = async () => {
    try {
      const web3 = new Web3("https://arbitrum-one-rpc.publicnode.com");

      const routerContract = new web3.eth.Contract(abi, router);
      if (tokenA.name !== "CPT") {
        setAmountIn(etherBalance);
      } else {
        setAmountIn(tokenBalance);
      }
    } catch (err) {
      console.error("Error setting up max:", err);
      toast({
        status: "error",
        title: "Error",
        description: err.message || "Insufficient funds to max out.",
        isClosable: true,
        colorScheme: "red",
        position: "top-right",
        duration: 3000,
      });
    }
  };

  return (
    <Stack w={"full"} h={"100vh"} className="background" spacing={8}>
      <HStack
        className="responsive-header"
        w={"full"}
        justifyContent={"space-between"}
        p={8}
        px={20}
        bgColor={"transparent"}
      >
        <Image src={logo} />
        <Button
          className="header-button"
          bgColor={"#311C31"}
          borderRadius={14}
          alignItems={"center"}
          justifyContent={"center"}
          w={"120px"}
          color={"#E836F2"}
          height={"35px"}
          fontSize={"large"}
          cursor={"pointer"}
          onClick={() => {
            walletAddress === null ? onOpen() : disconnectWallet();
          }}
        >
          {walletAddress === null ? "Connect" : "Disconnect"}
        </Button>
      </HStack>

      <Stack alignItems={"center"} spacing={6}>
        <Text
          className="responsive-swap"
          m={0}
          pb={5}
          color={"white"}
          fontSize={"xxx-large"}
          fontWeight={"semi-bold"}
        >
          Swap on Arbitrum
        </Text>

        <VStack
          className="main-box"
          borderRadius={14}
          spacing={4}
          // width={"450px"}
        >
          <VStack width={"full"} spacing={2}>
            <Text
              style={{ fontSize: "25px" }}
              color={"white"}
              alignSelf={"flex-start"}
            >
              Sell
            </Text>
            <HStack width={"full"} justifyContent={"space-between"}>
              <HStack
                className="input-style"
                borderRadius={8}
                justifyContent={"center"}
                alignItems={"center"}
                height={"80px"}
                px={4}
              >
                <Input
                  className="responsive-input"
                  bgColor={"transparent"}
                  color={"white"}
                  placeholder="0.0"
                  borderRadius={8}
                  border={"none"}
                  focusBorderColor={"transparent"}
                  outline={"none"}
                  flex={1}
                  size={"lg"}
                  fontSize={"24px"}
                  height={"80px"}
                  value={amountIn}
                  onChange={handleAmountInChange}
                />
                <VStack>
                  <HStack>
                    <Image src={tokenA.image} alt="ETH" boxSize={25} mr={2} />
                    <Text color={"white"} fontSize={"xl"}>
                      {tokenA.name}
                    </Text>
                  </HStack>
                  <Text
                    color={"white"}
                    fontSize={"small"}
                    whiteSpace={"nowrap"}
                    mt={-2}
                  >
                    Balance:{" "}
                    {tokenA.name === "ETH" ? etherBalance : tokenBalance}
                  </Text>
                </VStack>
              </HStack>
            </HStack>
          </VStack>

          <Button
            bgColor={"#311C31"}
            color={"#E836F2"}
            className="responsive-button"
            w={"200px"}
            height={"50px"}
            fontSize={"22px"}
            borderRadius={14}
            cursor={"pointer"}
            onClick={handleMax}
          >
            MAX
          </Button>

          <Box p={2}>
            <IconButton
              className="iconBtn"
              icon={<FaArrowDown />}
              bgColor={"transparent"}
              color={"white"}
              borderRadius={"full"}
              size={"lg"}
              aria-label={"Swap tokens"}
              onClick={switchToken}
            />
          </Box>

          <VStack width={"full"} spacing={2}>
            <Text
              style={{ fontSize: "25px" }}
              color={"white"}
              alignSelf={"flex-start"}
            >
              Buy
            </Text>
            <HStack width={"full"} justifyContent={"space-between"}>
              <HStack
                bgColor={"#2C2C2C"}
                className="input-style"
                borderRadius={8}
                w={"full"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"80px"}
                px={4}
              >
                <Input
                  // bgColor={"#2C2C2C"}
                  className="responsive-input"
                  bgColor={"transparent"}
                  color={"white"}
                  placeholder="0.0"
                  borderRadius={8}
                  border={"none"}
                  focusBorderColor={"transparent"}
                  outline={"none"}
                  flex={1}
                  size={"lg"}
                  fontSize={"24px"}
                  readOnly={true}
                  height={"80px"}
                  value={amountIn === 0 || amountIn === "" ? "" : amountOut}
                />

                <VStack>
                  <HStack>
                    <Image src={tokenB.image} alt="ETH" boxSize={25} mr={2} />
                    <Text color={"white"} fontSize={"xl"}>
                      {tokenB.name}
                    </Text>
                  </HStack>
                  <Text
                    color={"white"}
                    fontSize={"small"}
                    whiteSpace={"nowrap"}
                    mt={-2}
                  >
                    Balance:{" "}
                    {tokenB.name === "ETH" ? etherBalance : tokenBalance}
                  </Text>
                </VStack>
              </HStack>
            </HStack>
          </VStack>

          <Button
            bgColor={"#311C31"}
            color={"#E836F2"}
            className="header-button responsive-button"
            w={"300px"}
            height={"60px"}
            fontSize={"22px"}
            borderRadius={14}
            cursor={"pointer"}
            mt={8}
            onClick={() => {
              if (walletAddress === null) {
                onOpen();
              } else if (isMC) {
                swapTokensMM();
              } else if (isWC) {
                handleTransaction();
              }
            }}
          >
            {walletAddress === null ? "Connect wallet" : "SWAP"}
          </Button>
        </VStack>
      </Stack>
      <Stack>
        <Center>
          <Modal size={"md"} onClose={onClose} isOpen={isOpen} isCentered>
            <ModalOverlay />
            <ModalContent
              bg={"#141414"}
              className="wallet-responsive"
              borderRadius={10}
              w={{ base: "90%", md: "50%" }}
              maxW={"400px"}
              mx={"auto"}
              mt={"30vh"}
              p={12}
            >
              <ModalHeader
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                color={"white"}
              >
                <Text>Connect Wallet</Text>
                <ModalCloseButton
                  borderRadius={10}
                  bgColor={"transparent"}
                  bg={"none"}
                  color={"white"}
                  border="none"
                  cursor={"pointer"}
                  _hover={{ bg: "none" }}
                  _active={{ bg: "none" }}
                  _focus={{ boxShadow: "none" }}
                />
              </ModalHeader>
              <ModalBody className="wallet-responsive">
                <VStack className="wallet-responsive" spacing={5}>
                  <Stack className="wallet-responsive" spacing={5}>
                    <HStack
                      cursor={"pointer"}
                      borderRadius={10}
                      bgColor={"#222323"}
                      className="wallet-responsive"
                      w={"390px"}
                      px={4}
                      onClick={() => {
                        onClose();
                        connectWallet();
                      }}
                    >
                      <Image boxSize={"50px"} src={mm} />
                      <Text fontSize={"large"} color={"white"}>
                        MetaMask
                      </Text>
                    </HStack>
                  </Stack>
                  <Stack className="wallet-responsive" spacing={5}>
                    <HStack
                      cursor={"pointer"}
                      borderRadius={10}
                      bgColor={"#222323"}
                      className="wallet-responsive"
                      w={"390px"}
                      px={4}
                      onClick={() => {
                        onClose();
                        WalletConnect();
                      }}
                    >
                      <Image boxSize={"50px"} src={wc} />
                      <Text fontSize={"large"} color={"white"}>
                        Wallet Connect
                      </Text>
                    </HStack>
                  </Stack>
                </VStack>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Center>
      </Stack>
    </Stack>
  );
}

export default App;
